.TError_error {
    border: 2px dashed red;
    padding: 20px;
}

.TError {
    margin-bottom: 20px;
}
.Articles-container {
    display: flex;
    flex-direction: column;
}

.Article {
    border: 4px solid black;
    border-radius: 15px;
    margin: 5px;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 15px;
    padding: 5px;
}

.overflow-x {
    overflow-x: auto;
}

.Article-below-title {
    color: gray;
    font-size: 8pt;
    margin: 0;
}

.Article-title {
    margin: 7px;
}

.Article-status-btns {
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
    margin-bottom: 10px;
}

.Article-added {
}

.Article-id {
    float: right;
}

.Articles-add-container {
    display: inline;
    font-size: 14pt;
    margin: 7px;
}

.Articles-add-button {
    margin: 20px;
}
.Button-style-1 {
    font-weight: bold;
    font-size: 12pt;
    background-color: white;
    border-radius: 3px;
    border-style: solid;
    border-color: black;
}

.Button-style-big {
    padding: 5px;
    font-size: 12pt;
    border-width: 3px;
}
.Rating {
    display: flex;
}

.Rating-btns {
    display: flex;
    justify-content: space-between;
    min-width: 70px;
    max-width: 100px;
    align-items: baseline;

    font-size: 15pt;
}

.Rating-caption {
    font-size: 15pt;
    margin-right: 10px;
}
.Tags-caption {
    font-size: 15pt;
    margin-left: 3px;
}

.Tags-container {
    display: flex;
    flex-direction: column;
    font-size: 14pt;
}

.Tags-add-container {
    font-size: 14pt;
    display: inline;
    margin: 7px;
}

.Tags-add-button {
    margin: 20px;
}

.Tag {
    position: relative;
    left: -7px;
    font-size: 26pt;
    display: flex;
    align-items: center;
}

.Tag-content {
    font-size: 11pt;
    font-family: monospace;
    display: inline;
    border: 2px solid;
    border-radius: 5px;
    padding: 3px;
}

.Tag-delete {
    font-size: 10pt;
    margin-left: 20px;
}
.Articles_Add {
    border: 5px solid blue;
    padding: 30px;
}
.react-json-view {
    overflow: auto;
}
