.Button-style-1 {
    font-weight: bold;
    font-size: 12pt;
    background-color: white;
    border-radius: 3px;
    border-style: solid;
    border-color: black;
}

.Button-style-big {
    padding: 5px;
    font-size: 12pt;
    border-width: 3px;
}