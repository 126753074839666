.Articles-container {
    display: flex;
    flex-direction: column;
}

.Article {
    border: 4px solid black;
    border-radius: 15px;
    margin: 5px;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 15px;
    padding: 5px;
}

.overflow-x {
    overflow-x: auto;
}

.Article-below-title {
    color: gray;
    font-size: 8pt;
    margin: 0;
}

.Article-title {
    margin: 7px;
}

.Article-status-btns {
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
    margin-bottom: 10px;
}

.Article-added {
}

.Article-id {
    float: right;
}

.Articles-add-container {
    display: inline;
    font-size: 14pt;
    margin: 7px;
}

.Articles-add-button {
    margin: 20px;
}