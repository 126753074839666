.Rating {
    display: flex;
}

.Rating-btns {
    display: flex;
    justify-content: space-between;
    min-width: 70px;
    max-width: 100px;
    align-items: baseline;

    font-size: 15pt;
}

.Rating-caption {
    font-size: 15pt;
    margin-right: 10px;
}